import React from "react"
import { Layout } from "../components/Layout"
import {
  CardInfo,
  SectionDivider,
  SectionDividerMobile,
  ServiceSection,
  DemoButton,
  GetStartedButton,
  LearnMoreButton,
  CountsSection,
  Faq,
  AutoScrollBanner
} from "../commonComponents"
import { Helmet } from "react-helmet"

export default function RoutingGuide() {
  return (
    <Layout
      keywords="ticket routing, customer sentiment, ticket sentiment, Zendesk ticket sentiment"
      title="Automate Ticket Routing to the Right Support Agent"
      description="Enhance customer satisfaction with automated ticket routing. Our solution intelligently directs support tickets to the most qualified agent, streamlining workflows, reducing response times, and ensuring optimal issue resolution. Explore the benefits of precision in customer support."
    >
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <link
          rel="canonical"
          href="https://irisagent.com/routing/"
        />
      </Helmet>
      {/* <!-- ======= Hero Section ======= --> */}
      <section
        id="hero-new-design"
        className="agent-assistant-section-padding-top section-padding-right"
      >
        <div className="row" style={{ width: "100%", margin: 0 }}>
          <div
            className="col-md-6 section-padding-left"
            style={{ paddingLeft: "0" }}
          >
            <h1 className="title-support-new-design">
              <span style={{ color: "#EF5DA8" }}>Automatically Route</span>
              <br />
               support tickets to the right agent
            </h1>
            <div className="description-support-new-design web-view-new-design-display">
              Intelligent route tickets to the right experts. Configure<br/>
              accurate and efficient automated ticket routing based on<br/>
              agent skills, availability, expertise, workflow, and ticket <br/>
              complexity. Reducing response time and ensuring that tickets <br/>
              are assigned for optimal issue resolution.
            </div>
            <div className="description-support-new-design mobile-view-new-design-display">
              Intelligent route tickets to <br/>
              the relevant experts. Configure routing model <br/>
              based on agent skills, availability, expertise,<br/>
              workflow, and ticket complexity.
            </div>
            <br />
            <div className="description-button-support-new-design">
              <LearnMoreButton

            label="TRY NOW FOR FREE"
            to="/try-for-free"
          />
            </div>
          </div>
          <div className="col-md-6" style={{ padding: 0 }}>
            <div className="routing-background"></div>
          </div>
        </div>
      </section>

      <AutoScrollBanner />
      <section class="inner-page">
          <div class="container">
            <div data-custom-class="body"
            style={{ marginBottom: "5%", marginTop: "5%", margin:"10%" }}>


                    <h2>
                    Getting Started with Automatic Ticket Routing
                    </h2>
                    <br/>

                    IrisAgent provides a robust and configurable rule engine to automate your ticket routing process based on agent skills, availability, business hours, ticket intent/tags, statuses, and priority, and configurable dynamic rules. It supports dynamic rules such as setting up soft and hard limits on the number of assigned cases and real-time queue size. To further enhance customer service efficiency, IrisAgent's rule engine can automate ticket routing, streamlining the process by automatically assigning customer inquiries to the most suitable support reps based on criteria like submission time and channel used, ensuring quicker and more accurate responses. <br/><br/>
                    Because IrisAgent also automates ticket tagging and prioritization and agent skill assessment, it can intelligently identify domain experts and route tickets based on ticket tags and priority.
                    <br/>
                    <br/>
                    <h2>
                    Setting up Ticket Routing Rules
                    </h2>
                    <br/>
                    The Triggers product can support composite conditions and actions, enabling tickets to be automatically assigned based on these conditions. For instance, you can set up rules to automatically route billing inquiry tickets to available agents in the billing sub-team while ensuring workload limits and agent work hours are enforced.
                        <img
                src="/img/routing.png"
                style={{
                  width: "80%",
                  padding: "5%",
                }}
                alt="SSO"
              /><br/>

              In addition, you can add intelligence to your Routing trigger by leveraging AI-powered ticket priority and customer health signals to ensure that critical tickets are automatically assigned to the experts in your team.
              <br/><img
                src="/img/smart-routing.png"
                style={{
                  width: "80%",
                  padding: "5%",
                }}
                alt="SSO"
              /><br/>

              <h2>
                    Managing Agent Availability and Skills
                    </h2>
                    <br/>
                    <img
                src="/img/routing-setting.svg"
                style={{
                  width: "100%",
                  padding: "5%",
                }}
                alt="SSO"
              /><br/>
                    You can add fine-grained agent details and routing controls and configuration options. These options can be directly imported from your ticketing systems, e.g., Zendesk, Salesforce, etc., or imported from a custom channel, such as Calendar apps, documentation pages, CSV files, etc. Alternatively, you can also manage all of this information in IrisAgent's admin portal. Below is a comprehensive list of agent details and routing controls.

                    <br/><br/>
                    <h4>
                    Creating Ticket Routing Rules based on Agent details
                    </h4>  <br/>
                    You can set up ticket routing triggers based on any or all parameters in the below Agent details: <br/><br/>

                    <ol>
                    <li>
                    Time-off
                    </li>
                    <li>
                    Work hours
                    </li>
                    <li>
                    Time zone
                    </li>
                    <li>
                    Skills or expertise: This can either be manually entered or imported, or automatically computed based on AI.
                    </li>
                    <li>
                    Maximum workload limits
                    </li>
                    <li>
                    Current workload: Number of open cases in real-time, computed automatically.
                    </li>
                    </ol>

<br/>
                    <h4>
                    Creating Routing Rules based on different Routing algorithms
                    </h4>  <br/>
                    You can set up routing triggers based on any or all Routing algorithms: <br/><br/>

                    <ol>
                    <li>
                    Round Robin: Routing among available agents based on a basic approach of choosing all agent equally in.
                    </li>
                    <li>
                    Weighted by Workload and Limits: Routing among available agents based on workload of the agents while enforcing individual agent's workload limits.
                    </li>
                    </ol>

                    <h2>
                    <br/>
                    Routing Conversations created from IrisAgent's Chatbot, IrisGPT
                    </h2>
                    <br/>
                    <a href="https://irisagent.com/support-agent-assist/">IrisGPT</a> is an AI-powered chatbot that can be deployed on your website. IrisGPT can be configured to
                    automatically create tickets in your ticketing system, most companies such as Zendesk, Salesforce, etc. either
                    when the user asks to speak to agent, or when the user asks a question that IrisGPT cannot answer,
                    or based on any preconfigured triggers. You can set up routing triggers based on any or all
                    parameters to route conversations to agents based on two approaches as below. <br/><br/>

                    <h4>
                    Routing Conversations based on Ticketing System's Routing Rules
                    </h4>
                    IrisAgent can apply tags to tickets originating from a conversation in IrisGPT chatbot. You can set up
                    routing triggers in your Ticketing System that can read those applied tags. These tags can be based
                    on any or all parameters in the below Ticket details: <br/><br/>
                    <ol>
                    <li>
                    Intent of the conversation
                    </li>
                    <li>
                    User details
                    </li>
                    <li>
                    Ticket tags
                    </li>
                    <li>
                    Ticket priority
                    </li>
                    <li>
                    Any custom attribute in the ticket derived from the ticketing system or customer's backend APIs
                    </li>
                    </ol>


                    <h4>
                    Routing Conversations using IrisAgent's Routing Rules
                    </h4>
                    IrisAgent can route conversations using its inbuilt Routing Engine as described in the previous
                    sections. You can set up routing triggers based a number of parameters e.g. agent availability,
                    agent skills, agent workload, etc. <br/><br/><br/>

                    <h2>
                    Leveraging IrisAgent's AI to turbocharge automated ticket routing
                    </h2>
                    <br/>
                    IrisAgent’s AI revolutionizes the automated ticket routing system by introducing advanced capabilities that streamline the process and enhance efficiency, especially in quickly assigning and responding to customer inquiries. Leveraging cutting-edge technology, IrisAgent automates ticket routing with precision and intelligence, optimizing workflows and improving customer service. The role of AI in ensuring efficient ticket routing is pivotal, as it guarantees every support ticket is assigned to the relevant owner, enhancing productivity and customer satisfaction.
                    <br/><br/>
                    <h4>
                    Automatic Application of Product Tags
                    </h4>
                    IrisAgent's AI automatically applies product tags to tickets, ensuring that they are routed to the right agents. By analyzing the content of the tickets, IrisAgent identifies the products or services mentioned and tags the tickets accordingly. This ensures that tickets are routed to agents with the relevant expertise, enabling them to provide accurate and efficient support.
                    <br/><br/>
                    <h4>
                    Smart Prioritization of Tickets
                    </h4>
                    IrisAgent's AI analyzes incoming tickets and assigns priority levels based on various factors such as urgency, complexity, and customer value. By considering these factors, IrisAgent ensures that critical tickets are promptly routed to the most suitable agents, enabling them to provide timely and effective support. IrisAgent's AI-powered ticket prioritization feature is designed to enhance the efficiency and effectiveness of ticket routing.
                    <br/><br/>
                    <h4>
                    Intelligent Workload Balancing
                    </h4>
                    IrisAgent's AI-powered workload balancing feature is designed to optimize the distribution of tickets among available agents. By analyzing agent availability, capacity, and expertise, IrisAgent intelligently balances the workload, ensuring that tickets with critical issues are routed to agents with the right skills and capacity. This prevents bottlenecks, optimizes response times, ensures a fair ticket distribution process, and enhances the overall efficiency of the support team.
                    <br/><br/>
                    <h4>
                    Seamless Integration and Customization
                    </h4>
                    IrisAgent’s AI seamlessly integrates with existing ticketing systems and can be customized to meet the specific needs of each ticket, organization and customer experience. By leveraging IrisAgent’s AI capabilities, organizations can streamline their ticket routing processes and deliver easy tickets with exceptional customer support. This integration also significantly enhances the management and resolution of the ticket queue, ensuring that agents can efficiently address and prioritize tasks.
                    <br/><br/>
                    </div>
                    </div>
                    </section>

        <section
        className="d-flex align-items-center sub-section-3-container"
        // style={{ margin: "100px" }}
      >
        <div className="">
          <div className="row">
            <div className="col-sm order-1 order-lg-1">
              <div style={{ marginLeft: "40px" }}>
                <p className="sub-section-3-h1">Any questions?</p>
                <p className="sub-section-3-h2"> We got you.</p>
                <img
                  src="/img/security-5.svg"
                  className="img-fluid"
                  alt="secure"
                />
              </div>
            </div>
            <div className="col-sm pt-5 pt-lg-0 order-2 order-lg-2 d-flex flex-column justify-content-center">
              <div id="accordion">
                <Faq
                  id="faq1"
                  question="How does an automated ticket routing system work?"
                  answer="An automated ticket routing system utilizes intelligent algorithms to analyze ticket content, customer profiles, and agent skills based routing to prioritize tickets to the most appropriate agent. By matching these factors to the most appropriate agent with customer query, for example, the automated and efficient ticket routing engine ensures that next ticket is directed to the most qualified agent for efficient resolution."
                />
                <Faq
                  id="faq2"
                  question="What are the key benefits of different aspects of automated ticket routing process in ticket assignment?"
                  answer="Compared to the manual process of ticket routing, automated ticket routing process optimizes customer support operations by reducing manual workload, improving customer call response times for customers, and ensuring that customer support queries and support tickets are handled by agents with the necessary skills and relevant expertise nurturing customer relationships. This leads to faster responses for customers and increased customer satisfaction."
                />
                <Faq
                  id="faq3"
                  question="Can automated new ticket routing integrate with the existing routing tickets and other support ticket systems?"
                  answer="Yes, the solution is designed to seamlessly integrate with various customer support systems and ticketing platforms, such as Zendesk, Salesforce, Intercom, Freshdesk, and many others to automate ticket routing. This allows businesses to enhance their customer support team capabilities and customer satisfaction without disrupting customer service team's already established workflows."
                />
                <Faq
                  id="faq4"
                  question="What happens if there is a change in agent capacity, availability or skills?"
                  answer="The system is dynamic and can adapt to changes in customer service reps, agent availability or skills or response time. It continuously reassesses the customer service team's ticket queue routing based on real-time data, ensuring that tickets are always directed and automatically assigned to the right person and most suitable agent at resolution time."
                />
                <Faq
                  id="faq5"
                  question="How do I route chats and tickets to groups in Zendesk or other ticketing systems?"
                  answer="Using IrisAgent's skill based ticket routing and omnichannel routing, you would set up rules or triggers based on criteria or data like keywords, customer attributes, or ticket content. These rules then automatically assign incoming chats or tickets to the appropriate groups within your support team in Zendesk, ensuring efficient handling and resolution."
                />
                <Faq
                  id="faq6"
                  question="What is omnichannel routing in Zendesk with IrisAgent?"
                  answer="Omnichannel request routing in Zendesk with IrisAgent is a system that seamlessly directs customer inquiries from various channels like email, chat, or social media to the right support teams or agents, ensuring a cohesive and efficient customer service experience across all communication channels."
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- ======= Services Section ======= --> */}
      <ServiceSection />
      {/* <!-- End Services Section --> */}

      {/* <!-- ======= Contact Services Section ======= --> */}
      {/* <ContactSection /> */}
      {/* <!-- End Contact Section --> */}
    </Layout>
  )
}
