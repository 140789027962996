import React from "react"
import { Layout } from "../components/Layout"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

export default function TicketDeflectionGuide() {
  return (
    <Layout
      keywords="IrisAgent Ticket Deflection guide"
      title="Set up Ticket Deflection Automation on your IrisAgent account"
      description="Documentation on how to set up ticket deflection automations and workflows on your IrisAgent account to automate simple queries with AI."
    >
    <Helmet htmlAttributes={{ lang: 'en' }}>
        <link
          rel="canonical"
          href="https://irisagent.com/ticket-deflection-docs/"
        />
      </Helmet>
      {/* <!-- ======= Breadcrumbs ======= --> */}
      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <h2>IrisAgent Ticket Deflection Guide</h2>
            <ol>
              <li>
                <Link to="/irisagent-docs/">Home</Link>
              </li>
              <li>Ticket Deflection Guide</li>
            </ol>
          </div>
        </div>
      </section>
      {/* <!-- End Breadcrumbs --> */}
      <section class="inner-page">
          <div class="container">
            <div data-custom-class="body"
            style={{ marginBottom: "5%", marginTop: "5%", margin: "10%" }}>

                    <h1>IrisAgent Ticket Deflection Guide</h1>
                    <br/>
                    <h2>
                    Introduction
                    </h2>
                    <br/>

                    IrisAgent provides recommended AI answers from knowledge base and past tickets that can be used to automatically reply to incoming tickets without waiting for the agent. This reduces the time to respond to tickets and improves customer satisfaction.
                    <br/><br/>
                    The AI answers in the "Suggested Resolution" widget that you see on the IrisAgent app within your ticketing system are the same answers that are sent out to customers automatically. The answers are generated by our AI models that are trained on your knowledge base and past tickets.
                    <br/>
                    <br/>
                    <h2>
                    Set up Ticket Deflection
                    </h2>
                    <br/>
                    <h3>Set up Ticket Deflection on All Tickets</h3>
                    <br/>

                    We recommend using this feature carefully as all tickets that have an AI answer will get an auto-response. Please see the recommended way to set up deflection on specific tickets below.
                    <br/><br/>
                    On our dashboard, go to <b>Automation</b> -> <b>Triggers</b>, and toggle on the <b>Enable Case Deflection</b> switch to enable this feature.
                    <br/><br/>
                    You can disable this feature anytime using the same switch.
                    <br/><br/>
                    <img
                        src="/img/ticket_deflection_switch.png"
                        style={{
                          width: "50%",
                          padding: "5%",
                        }}
                        alt="Ticket Deflection Toggle Switch"
                    /><br/><br/>
                    Once enabled, you can review all the tickets that received an AI auto-response and the associated deflection metrics by clicking on the new trigger that was created automatically called <b>System: Case Deflection</b>.
                    <br/><br/>
                    <h3>Set up Ticket Deflection on Specific Tickets</h3>
                    <br/>
                    This is our recommended option to enable ticket deflection safely on a specific type of tickets.
                    <br/><br/>
                    <ol>
                    <li>
                    On our dashboard, go to <b>Automation</b> -> <b>Triggers</b>, and click on <b>Create New Trigger</b>.
                    </li>
                    <li>
                    Add the conditions that you want to trigger the deflection from the right panel on <b>Conditions</b>. For example, you can add a condition to trigger deflection only when the ticket has a particular tag or contains certain keywords.
                    <img
                        src="/img/trigger_conditions.png"
                        style={{
                          width: "50%",
                          padding: "5%",
                        }}
                        alt="Trigger Conditions"
                    /><br/>
                    </li>
                    <li>
                    Add the action <b>Auto-respond to customer</b> from the <b>Actions</b> panel on the right.
                    </li>
                    <li>
                    Add the text <b>&#123;&#123;iris.gpt.case.answer&#125;&#125;</b> in the text box. This will automatically insert the AI answer generated by IrisAgent. You can add other text before or after this tag.<br/>
                    <img
                        src="/img/full_deflection_trigger.png"
                        style={{
                          width: "80%",
                          padding: "5%",
                        }}
                        alt="Full Ticket Deflection Trigger"
                    /><br/>
                    </li>
                    <li>
                    Give a name to this trigger on the top by clicking on the pencil icon
                    </li>
                    <li>
                    Click on <b>Add New Trigger</b> to save the trigger.
                    </li>
                    </ol>
                    Once enabled, you can review all the tickets that received an AI auto-response and the associated deflection metrics by clicking on the this trigger that you created.
                    


<br/><br/>

Feel free to <a href="mailto:contact@irisagent.com" target="_blank">email us</a> if you encounter any issues or require assistance with product onboarding.
                    </div>
                    </div>
                    </section>

</Layout>
  )
}
