import React from "react"
import { Layout } from "../components/Layout"
import {
  SectionDivider,
  SectionDividerMobile,
  ServiceSection,
  DemoButton,
  LearnMoreButton,
  GetStartedButton,
  CountsSection,
  Testimonials,
  Faq,
  AutoScrollBanner
} from "../commonComponents"
import {
  IntegrationDarkSection
} from "../integrationsUtils"
import { Helmet } from "react-helmet"

export default function FinTechSupportAI() {
  return (
    <Layout
      keywords="fintech ai agent assist, fintech agent assist bot, conversational ai banking, conversational ai in financial services"
      title="Best AI Agent Assist and Chatbot for FinTech | IrisAgent"
      description="Transform fintech support experiences with AI-powered agile, effective and human-like automated responses for online customer interactions."
    >
      <Helmet htmlAttributes={{ lang: 'en' }}>
        {" "}
        <link
          rel="canonical"
          href="https://irisagent.com/fintech-support-ai/"
        />
      </Helmet>
      {/* <!-- ======= Hero Section ======= --> */}
      <section
        id="hero-new-design"
        className="agent-assistant-section-padding-top"
      >
        <div className="row" style={{ width: "100%", margin: 0 }}>
          <div
            className="col-md-6 section-padding-left"
            style={{ paddingLeft: "0" }}
          >
            <h1 className="title-support-new-design">
              <span style={{ color: "#EF5DA8" }}>Transform FinTech customer experiences </span> with smart AI responses
            </h1>
            <div className="description-support-new-design web-view-new-design-display">
            <ul className="checkbox-list">
              <li style={{ paddingLeft: "10px" }}> Automate up to 60% interactions by delivering 24X7 <br/>support to your customers </li>
              <li style={{ paddingLeft: "10px" }}> Enable virtual banking assistance for interactions with <br/>GenAI bots </li>
              <li style={{ paddingLeft: "10px" }}> Assist frontline teams and with relevant information </li>
            </ul>
            </div>
            <div className="description-support-new-design mobile-view-new-design-display">
              <ul className="checkbox-list">
              <li style={{ paddingLeft: "10px" }}> Automate up to 60% interactions by <br/>delivering 24X7 support to your<br/> customers </li>
              <li style={{ paddingLeft: "10px" }}> Enable virtual banking assistance for<br/> interactions with GenAI bots </li>
              <li style={{ paddingLeft: "10px" }}> Assist frontline teams and with relevant <br/>information </li>
            </ul>
            </div>
            <br />
            <div className="description-button-support-new-design">
              <LearnMoreButton

            label="TRY NOW FOR FREE"
            to="/try-for-free"
          />
            </div>
          </div>
          <div className="col-md-6" style={{ padding: 0 }}>
            <div className="fintech-ai-background"></div>
          </div>
        </div>
      </section>
      {/* <!-- End Hero --> */}

      <AutoScrollBanner />

{/*  ======= Cta Section =======  */}
      <IntegrationDarkSection
        label={
          <span className="integration-dark-section-title">
            Try{" "}
            <span className="title-fucsia-color">IrisGPT</span> on your own data for free
          </span>
        }
        button={
          <LearnMoreButton

            label="TRY NOW FOR FREE"
            to="/try-for-free"
          />
        }
        firstColClassName="col-md-8"
        secondColClassName="col-md-4"
        buttonWebFlexAlignment="end"
      />
      {/*  End Cta Section  */}
      <section
        id="about"
        className="about auto-detect-section auto-detect-section-less-top section-padding-right"
        style={{ marginBottom: "5%", marginLeft: "4%", marginTop: "3%" }}
      >
        <div className="row">
          <div className="col-md-12" style={{ display: "flex", width: "100%" }}>
            <SectionDividerMobile color="#7879F1"></SectionDividerMobile>
            <div
              className="auto-detect-img-not-mobile"
              style={{
                width: "40%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src="/img/irisgpt.svg"
                style={{
                  width: "95%"
                }}
                alt="about section"
              />
            </div>
            <SectionDivider color="#7879F1"></SectionDivider>
            <div
              className="auto-detect-info-container-50"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <div className="auto-detect-subcontainer-mobile">
                <div className="mobile-view-new-design-display">
                  <img
                    src="/img/irisgpt.svg"
                    alt="about section"
                    style={{ width: "88%", paddingBottom: "10%" }}
                  />
                </div>
                <h2
                  className="section-title-new-design auto-detect-title agent-assistant-auto-detect-title"
                  style={{ marginBottom: "5%" }}
                >
                <span>
                  Leverage AI and automated workflows for your banking interactions
                 </span>

                 </h2>
                  <div className="generic-text"
                  style={{ marginBottom: "5%" }}
                  >
                  <span className="web-view-new-design-display">
                  <ul className="checkbox-list">
                    <li style={{ paddingLeft: "20px" }}>  IrisGPT GenAI bot using LLMs efficiently responds to 60%+ queries human-like responses. Automate tasks like opening accounts, loan applications, balance enquiries, account benefits. </li>
                    <li style={{ paddingLeft: "20px" }}>  Automated workflows with AI driven intent detection of all interactions and efficiently route and resolve issues faster. No more inaccurate manual monitoring and routing for inbound enquiries! </li>
                  </ul>
                   </span>
                   <span className="mobile-view-new-design-display">
                    <ul className="checkbox-list">
                    <li style={{ paddingLeft: "20px" }}>  IrisGPT GenAI bot using LLMs efficiently responds to 60%+ queries human-like responses. Automate tasks like opening accounts, loan applications, balance enquiries, account benefits. </li>
                    <li style={{ paddingLeft: "20px" }}>  Automated workflows with AI driven <br/>intent detection of all interactions and efficiently route and resolve issues faster. <br/>No more inaccurate manual monitoring <br/>and routing for inbound enquiries! </li>
                  </ul>
                   </span>
                  </div>
                <div style={{ display: "flex" }}>
                  <DemoButton />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End About Auto-detected incidents Section --> */}
      {/* <!-- ======= About Similar Cases Section ======= --> */}
      <section
        id="about"
        className="about auto-detect-section auto-detect-section-less-top section-padding-left-mobile section-padding-right"
        style={{ marginBottom: "5%" }}
      >
        <div className="row">
          <div className="col-md-12" style={{ display: "flex", width: "100%" }}>
            <SectionDividerMobile color="#EF5DA8"></SectionDividerMobile>
            <div
              className="auto-detect-info-container-40"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div>
                <div className="mobile-view-new-design-display">
                  <img
                    src="/img/sentiment.webp"
                    style={{
                      width: "94%",
                      paddingBottom: "10%",
                    }}
                    alt="about section"
                  />
                </div>
                <div
                  className="section-title-new-design auto-detect-title"
                  style={{ marginBottom: "5%" }}
                >
                  <h2
                    className="section-title-new-design auto-detect-title"
                    style={{ marginBottom: "5%" }}
                  >
                    <span>
                      Empower agents and Prevent escalations with real-time sentiment analysis
                      <br />
                    </span>
                    </h2>
                    <div className="generic-text"
                    style={{ marginBottom: "5%" }}
                    >
                    <span className="web-view-new-design-display">
                    <ul className="checkbox-list">
                      <li style={{ paddingLeft: "20px" }}> Give agents real-time insights from knowledge bases, historical ticketing data, and DevOps and engineering systems </li>
                      <li style={{ paddingLeft: "20px" }}> Discover at-risk tickets with automated revenue and customer sentiment impact analysis </li>
                      <li style={{ paddingLeft: "20px" }}> Get real-time alerts about trending product and customer experience issues. Custom-made and fine-tuned for FinTech companies. </li>
                    </ul>
                    </span>

                    <span className="mobile-view-new-design-display">
                      <ul className="checkbox-list">
                      <li style={{ paddingLeft: "20px" }}> Give agents real-time insights from knowledge bases, historical ticketing data, and DevOps and engineering systems </li>
                      <li style={{ paddingLeft: "20px" }}> Discover at-risk tickets with automated revenue and customer sentiment impact analysis </li>
                      <li style={{ paddingLeft: "20px" }}> Get real-time alerts about trending <br/>product and customer experience issues. Custom-made and fine-tuned for FinTech companies. </li>
                    </ul>
                    </span>
                    </div>
                </div>
                <div style={{ display: "flex" }}>
                  <GetStartedButton />
                </div>
              </div>
            </div>
            <SectionDivider color="#EF5DA8" flipped={true}></SectionDivider>
            <div
              className="auto-detect-img-not-mobile"
              style={{
                width: "50%",
                display: "flex",
                alignItems: "baseline",
                justifyContent: "flex-start",
              }}
            >
              <img
                src="/img/sentiment.webp"
                style={{
                  width: "90%",
                }}
                alt="about section"
              />
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End About Similar Cases Section --> */}

       <section className="d-flex align-items-center  sub-section-2-container">
        <div style={{ width: "100%" }}>
          <div className="row">
            <SecurityCard
              title="Secure Hosting"
              body="IrisAgent SaaS platform is hosted in a secure cloud infrastructure"
              image="/img/security-2.svg"
            />
            <SecurityCard
              title="Encrypted Data"
              body=" All the data collection happens securely over SSL using REST APIs"
              image="/img/security-3.svg"
            />
            <SecurityCard
              title="Single Sign-On"
              body="We do not require additional login credentials. We authenticate through Google, Microsoft and Salesforce."
              image="/img/security-4.svg"
            />
          </div>
        </div>
      </section>
      {/* <!-- ======= Counts Section ======= --> */}
      <CountsSection theme="light" />
      {/* <!-- End Counts Section --> */}


      {/* <!-- ======= Services Section ======= --> */}
      <ServiceSection />
      {/* <!-- End Services Section --> */}

            <section
        className="d-flex align-items-center sub-section-3-container"
        // style={{ margin: "100px" }}
      >
        <div className="">
          <div className="row">
            <div className="col-sm order-1 order-lg-1">
              <div style={{ marginLeft: "40px" }}>
                <p className="sub-section-3-h1">Any questions?</p>
                <p className="sub-section-3-h2"> We got you.</p>
                <img
                  src="/img/security-5.svg"
                  className="img-fluid"
                  alt="secure"
                />
              </div>
            </div>

                  <div className="col-sm pt-5 pt-lg-0 order-2 order-lg-2 d-flex flex-column justify-content-center">
              <div id="accordion">
                <Faq
                  id="faq1"
                  question="What is conversational AI in banking?"
                  answer="Conversational AI in banking refers to the use of artificial intelligence technologies, such as chatbots and virtual assistants, to facilitate interactive, human-like conversations between banks and their customers. These AI-powered systems are designed to understand and process natural language, allowing banking customers to perform banking tasks, get personalized financial advice, resolve queries, and receive instant support through text or voice commands. By automating customer service and enhancing user experience, conversational AI is transforming the way banks operate and interact with their clients, making banking more accessible, efficient, and personalized."
                />
                <Faq
                  id="faq2"
                  question="What is conversational banking?"
                  answer="Conversational banking is a customer service approach in banking that utilizes chatbots, voice assistants, and messaging platforms to provide a more interactive and personalized banking customer service experience. It allows customers to conduct transactions, access account information, receive financial advice, and get support through natural language conversations, either via text or voice. This method aims to make banking more accessible and convenient, enabling customers to manage their finances seamlessly in real-time, without the need for visiting physical branches or navigating complex banking websites."
                />
                <Faq
                  id="faq3"
                  question="How can AI be used in banking and financial services?"
                  answer="AI can be used in banking and the financial services industry to enhance customer service, improve security, and streamline operations. It enables personalized financial advice through chatbots, fraud detection with machine learning algorithms, and automated credit scoring for quicker loan approvals. AI also optimizes back-office processes, like document verification and risk management, and offers insights for better decision-making through data analysis. This integration of AI in banking leads to more efficient operations, reduced costs, and an improved customer experience, making banking more accessible, secure, and tailored to individual needs."
                />
                <Faq
                  id="faq4"
                  question="How is ChatGPT used in the banking industry?"
                  answer="ChatGPT is used in the banking industry to provide automated customer support, handle inquiries, and offer financial advice through natural language processing. It can answer questions, assist customers with transactions, and guide users through various banking services, enhancing customer experience with its ability to understand and respond in a conversational manner. Banks also utilize ChatGPT for internal purposes, such as training staff or automating routine tasks, improving operational efficiency, and reducing the workload on human employees."
                />
                <Faq
                  id="faq5"
                  question="Can conversational AI handle complex banking transactions?"
                  answer="Yes, advanced conversational AI systems are capable of handling complex transactions, such as transferring funds, using account balances, paying bills, or applying for loans, by securely processing user requests and integrating with the bank’s transactional systems."
                />
                <Faq
                  id="faq6"
                  question="How does conversational AI learn to handle new banking queries?"
                  answer="Conversational AI systems learn through machine learning algorithms and natural language processing, analyzing customer interactions, to improve their understanding and responses over time. Banks can also update AI systems with new information and rules to address emerging customer needs and banking products."
                />
              </div>
            </div>
                      </div>
        </div>
      </section>


      {/* <!-- ======= Contact Services Section ======= --> */}
      {/* <ContactSection /> */}
      {/* <!-- End Contact Section --> */}
    </Layout>
  )
}

// todo: move to commonComponents as Security page also uses it
const SecurityCard = ({ title, body, image }: any) => {
  return (
    <div
      className="col-sm order-1 order-lg-1 "
      style={{ textAlign: "center", marginLeft: "10px", marginRight: "10px" }}
    >
      <img src={image} className=" sub-section-2-img" alt="secure" />
      <h4 className="sub-section-2-h">{title}</h4>
      <p className="sub-section-2-p">{body}</p>
    </div>
  )
}
