import React from "react"
import { Helmet } from "react-helmet"

import { Layout } from "../components/Layout"
import "rc-tooltip/assets/bootstrap.css"
import Lottie from "react-lottie"
import animationDataGif from "../../static/img/gifIris.json"
import "react-responsive-carousel/lib/styles/carousel.min.css" // requires a loader
import { Carousel } from "react-responsive-carousel"
import {
    ServiceSection,
    MainVideo,
    SectionDivider,
    SectionDividerMobile,
    DemoButton,
    LearnMoreButton,
    CountsSection,
    AttributeSection,
    IntegrationsSection,
    ContactSection,
    HomeFeaturedSection,
    Testimonials,
    ProductSummaryWidget,
    AutoScrollBanner,
    GetStartedButtonWhite,
    VimeoPlayer
} from "../commonComponents"

import {
  IntegrationDarkSection
} from "../integrationsUtils"

export default function Home() {
  const TitleImage = () => {
    return (
      <div className="video-player-wrapper" >
        <VimeoPlayer url="https://vimeo.com/1050214160" />
      </div>
    )
  }

  const Video = () => {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationDataGif,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    }
    return (
      <div className="video-background video-hero-new-design">
        <Lottie options={defaultOptions} />
      </div>
    )
  }

  return (
    <Layout
        classes={{
            main: "section-spacing"
        }}
    >
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <link rel="canonical" href="https://irisagent.com/" />
      </Helmet>

      <section
        id="hero-new-design"

      >
        <div className="container w-100 mw-100 m-0">
            <div className={"row"}>
                <div
                    className={"col-lg-12 col-md-12 col-sm-24 col-xs-24 section-padding-left section-padding-right"}
                    style={{ paddingLeft: "0" }}
                >
                <br/>
                    <h1 className="title-support-new-design" style={{fontWeight: 500, textAlign: "center"}}>
                        Automate <span style={{ color: "#EF5DA8" }}>
            40% of tickets and chats </span>with human-like AI Agents
                    </h1>
                    <div className="description-support-new-design w-100" style={{textAlign: "center"}}>
                        Deliver accurate answers and perform actions safely. Get started in minutes.
                    </div>
                <br/>
                <div style={{ paddingTop: "3%", paddingBottom: "3%" ,textAlign:"center", display:"flex", gap:"20px", justifyContent:"center"}}>
                   <GetStartedButtonWhite
                   />
                   <LearnMoreButton
                    label="TRY FOR FREE"
                    to="/try-for-free"
                   />
                 </div>

                </div>
                <div className={"col-lg-12 col-md-12 col-sm-24 col-xs-24"} style={{paddingLeft: "12%", paddingRight: "12%"}}>
                    <TitleImage />
                </div>
            </div>
        </div>
      </section>
      {/* <!-- Hero Section --> */}

      {/* <!-- End Hero --> */}

      <AutoScrollBanner />

      {/* <!-- ======= Counts Section ======= --> */}
      <CountsSection theme="dark" />
      {/* <!-- End Counts Section --> */}
      {/* <!-- ======= Testimonials Section ======= --> */}
      <Testimonials />
      {/* <!-- End Testimonials Section --> */}

      {/* <!-- ======= Chatbot Section ======= --> */}
      <section
        id="about"
        className="about auto-detect-section section-padding-left section-padding-right"
        style={{ marginBottom: "2%", marginTop: "2%" }}
      >
        <div className="row">
          <div className="col-md-12" style={{ display: "flex", width: "100%" }}>
            <SectionDividerMobile color="#EF5DA8"></SectionDividerMobile>
            <div
              className="auto-detect-info-container-40"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div>
                <div className="mobile-view-new-design-display">
                  <img
                    className="clear-image-rendering"
                    src="/img/homeIlustration1.webp"
                    style={{
                      paddingBottom: "10%",
                      width: "100%",
                    }}
                    alt="about section"
                  />
                </div>
                <h2
                  className="section-title-new-design auto-detect-title"
                  style={{ marginBottom: "5%" }}
                >
                  AI-Powered Support: Instant, Intelligent Answers with context
                </h2>
                <div className="generic-text"
                  style={{ marginBottom: "5%" }}
                  >
                    <ul className="checkbox-list">
                  <li style={{ paddingLeft: "20px" }}>
                    With models fine-tuned on your article and ticket data, get AI answers at your fingertips.
                    </li>
                    <li style={{ paddingLeft: "20px" }}>
                    Always available AI agents that provide human-like service
                    </li>
                  </ul>
                  </div>
                <div style={{ paddingTop: "3%", paddingBottom: "3%" }}>
                  <LearnMoreButton

            label="TRY NOW FOR FREE"
            to="/try-for-free"
          />
                </div>
              </div>
            </div>
            <SectionDivider color="#7879F1" flipped={true}></SectionDivider>
            <div
              className="auto-detect-img-not-mobile"
              style={{
                width: "50%",
                display: "grid",
                placeItems: "center"
              }}
            >
              <img
                className="clear-image-rendering"
                src="/img/homeIlustrationAA1.webp"
                style={{
                  width: "100%",
                  padding: "3%",
                }}
                alt="about section"
              />
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End About Similar Cases Section --> */}



      {/* <!-- ======= About Auto-detected incidents Section ======= --> */}
      <section
        id="about"
        className="about auto-detect-section section-padding-left section-padding-right"
        style={{ marginBottom: "3%" }}
      >
        <div className="row">
          <div className="col-md-12" style={{ display: "flex", width: "100%" }}>
            <SectionDividerMobile color="#43CB9B"></SectionDividerMobile>
            <div
              className="auto-detect-img-not-mobile"
              style={{
                width: "50%",
                display: "grid",
                placeItems: "center"
              }}
            >
              <img
                className="clear-image-rendering"
                src="/img/homeIlustration3.svg"
                style={{
                  width: "120%",
                  padding: "3%",
                }}
                alt="about section"
              />
            </div>

            <SectionDivider color="#43CB9B"></SectionDivider>

            <div
              className="auto-detect-info-container-50"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <div className="auto-detect-subcontainer-mobile">
                <div className="mobile-view-new-design-display">
                  <img
                    className="clear-image-rendering"
                    src="/img/homeIlustration3.svg"
                    style={{
                      width: "90%",
                      marginLeft: "2%",
                    }}
                    alt="about section"
                  />
                </div>
                <h2
                  className="section-title-new-design auto-detect-title"
                  style={{ marginBottom: "5%" }}
                >
                  <span className="web-view-new-design-display">
                    Workflows to automate repetitive tasks, free up time, take actions on behalf of customers
                  </span>
                  <span className="mobile-view-new-design-display">
                    Workflows to automate repetitive tasks, free up time, and break x-functional silos
                  </span>
                </h2>
                <div className="generic-text"
                  style={{ marginBottom: "5%" }}
                  >
                    <ul className="checkbox-list">
                  <li style={{ paddingLeft: "20px" }}>
                    AI agents that go beyond Q&A and can take actions on customer's behalf by connecting with your backend systems
                    </li>
                    <li style={{ paddingLeft: "20px" }}>
                    Automate manual triaging, routing, and tagging, and scale your team
                    </li>
                  </ul>
                  </div>
                <div style={{ paddingTop: "3%", paddingBottom: "3%" }}>
                  <LearnMoreButton />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End About Auto-detected incidents Section --> */}

{/* <!-- ======= About auto-tagging Section ======= --> */}
      <section
        id="about"
        className="about auto-detect-section auto-detect-section-less-bottom section-padding-left-mobile section-padding-right"
        style={{ marginBottom: "2%", marginTop: "2%" }}
      >
        <div className="row">
          <div className="col-md-12" style={{ display: "flex", width: "100%" }}>
            <SectionDividerMobile color="#9B51E0"></SectionDividerMobile>
            <div
              className="auto-detect-info-container-40"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div>
                <div className="mobile-view-new-design-display">
                  <img
                    className="clear-image-rendering"
                    src="/img/homeIlustration4.webp"
                    style={{
                      width: "100%",
                      marginLeft: "-10%",
                    }}
                    alt="about section"s
                  />
                </div>
                <h2
                  className="section-title-new-design auto-detect-title"
                  style={{ marginBottom: "5%" }}
                >
                    Automated ticket tagging, triaging, and routing
                </h2>
                <div className="generic-text"
                  style={{ marginBottom: "5%" }}
                  >
                    <ul className="checkbox-list">
                  <li style={{ paddingLeft: "20px" }}>
                    IrisAgent discovers tags customized to your domain and automatically tags and routes new tickets
                    </li>
                    <li style={{ paddingLeft: "20px" }}>
                    Set custom answers and actions based on AI-powered intents and tags
                    </li>
                  </ul>
                  </div>
                <div style={{ paddingTop: "10%", paddingBottom: "3%" }}>
                  <LearnMoreButton />
                </div>
              </div>
            </div>
            <SectionDivider color="#9B51E0" flipped={true}></SectionDivider>
            <div
              className="auto-detect-img-not-mobile"
              style={{
                width: "50%",
                display: "grid",
                placeItems: "center"
              }}
            >
              <img
                src="/img/homeIlustration4.webp"
                style={{
                  width: "100%",
                  padding: "3%"
                }}
                alt="about section"
              />
            </div>
          </div>
        </div>
        <br />
      </section>
      {/* <!-- End About auto-tagging Section -->     */}

      {/* <!-- ======= About escalations Section ======= --> */}
      <section
        id="about"
        className="about auto-detect-section section-padding-left-mobile section-padding-right"
        style={{ marginBottom: "3%" }}
      >
        <div className="row">
          <div className="col-md-12" style={{ display: "flex", width: "100%" }}>
            <SectionDividerMobile color="#43CB9B"></SectionDividerMobile>
            <div
              className="auto-detect-img-not-mobile"
              style={{
                width: "50%",
                display: "grid",
                placeItems: "center"
              }}
            >
              <img
                className="clear-image-rendering"
                src="/img/sentiment.webp"
                style={{
                  width: "130%",
                  padding: "3%",
                }}
                alt="about section"
              />
            </div>
            <SectionDivider color="#43CB9B"></SectionDivider>
            <div
              className="auto-detect-info-container-50"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <div className="auto-detect-subcontainer-mobile">
                <div className="mobile-view-new-design-display">
                  <img
                    className="clear-image-rendering"
                    src="/img/sentiment.webp"
                    style={{
                      width: "120%",
                      marginLeft: "-10%",
                    }}
                    alt="about section"
                  />
                </div>
                <h2
                  className="section-title-new-design auto-detect-title"
                  style={{ marginBottom: "5%" }}
                >
                  <span className="web-view-new-design-display">
                    Predict and prevent escalations with real-time sentiment insights
                  </span>
                  <span className="mobile-view-new-design-display">
                    Predict and prevent escalations with real-time sentiment insights
                  </span>
                </h2>
                <div className="generic-text"
                  style={{ marginBottom: "5%" }}
                  >
                    <ul className="checkbox-list">
                  <li style={{ paddingLeft: "20px" }}>
                    Get real-time alerts when customer sentiment drops
                    </li>
                    <li style={{ paddingLeft: "20px" }}>
                    Proactively predict escalations using customer health, sentiment, and revenue signals
                    </li>
                  </ul>
                  </div>
                <div style={{ paddingTop: "3%", paddingBottom: "3%" }}>
                  <LearnMoreButton />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End About Auto-detected incidents Section --> */}


      {/* <!-- ======= Featured Section ======= --> */}
      {/*<HomeFeaturedSection />*/}
      {/* <!-- End Featured Section --> */}

      {/* <!-- ======= About Section ======= -->
      <section id="about" className="about section-padding-left section-padding-right">
        <div
          className="row"
          style={{ backgroundColor: "#F9F9F9", margin: "2%" }}
        >
          <div
            className="col-md-5"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className="about-section-title-new-design-container">
              <div className="about-section-title-new-design">
                Automated workflows and training{" "}
                <span style={{ color: "#3E4450" }}>
                  on siloed Support, Product, Engineering, and Knowledge Systems
                </span>
              </div>
            </div>
          </div>
          <div className="col-md-7">
            <img
              className="about-section-img"
              src="/img/aboutSection.webp"
              style={{
                width: "90%",
              }}
              alt="about section"
            />
          </div>
        </div>
      </section>
       <!-- End About Section --> */}


      {/* <!-- ======= Services Section ======= --> */}
      <AttributeSection></AttributeSection>
      {/* <!-- End Services Section --> */}
            {/*  ======= Cta Section =======  */}
      <IntegrationDarkSection
        label={
          <span className="integration-dark-section-title">
            Try{" "}
            <span className="title-fucsia-color">IrisGPT</span> on your own data for free
          </span>
        }
        button={
          <LearnMoreButton

            label="TRY NOW FOR FREE"
            to="/try-for-free"
          />
        }
        firstColClassName="col-md-8"
        secondColClassName="col-md-4"
        buttonWebFlexAlignment="end"
      />
      {/*  End Cta Section  */}

      {/* <!-- ======= Services Section ======= --> */}
      <ProductSummaryWidget />
      {/* <!-- End Services Section --> */}
      {/* <!-- ======= Services Section ======= --> */}
      <ServiceSection />
      {/* <!-- End Services Section --> */}




      {/* <!-- ======= Integrations Section ======= --> */}
      {/* <IntegrationsSection /> */}
      {/* <!-- End Integrations Section --> */}


    </Layout>
  )
}
