import React from "react"
import { Helmet } from "react-helmet"
import { Layout } from "../components/Layout"
import { Link } from "gatsby"

export default function IrisAgentDocs() {
  return (
    <Layout
      keywords="IrisAgent Docs Index"
      title="List of all documentation pages for IrisAgent"
      description="Documentation on how to get started with IrisAgent"
    >
        <Helmet htmlAttributes={{ lang: 'en' }}>
        <link
          rel="canonical"
          href="https://irisagent.com/irisagent-docs/"
        />
      </Helmet>
      {/* <!-- ======= Breadcrumbs ======= --> */}
      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <h2>Documentation</h2>
            <ol>
              <li>
                <Link to="/irisagent-docs/">Home</Link>
              </li>
            </ol>
          </div>
        </div>
      </section>
      {/* <!-- End Breadcrumbs --> */}

      <section class="inner-page">
          <div class="container">
            <div data-custom-class="body"
            style={{ marginBottom: "5%", marginTop: "5%" }}>
                    <h1>IrisAgent Getting Started Documentation</h1>
                    <br/>
                    <h2>Connect IrisAgent with your ticketing system</h2>
                    Integrate IrisAgent with your primary ticketing system and other sources from the options below:
                    <br/><br/>
                    <ul>
                    <li><a href="https://irisagent.com/zd-docs/">Integrate IrisAgent with Zendesk</a></li>
                    <li><a href="https://irisagent.com/sf-docs/">Integrate IrisAgent with Salesforce</a></li>
                    <li><a href="https://irisagent.com/fd-docs/">Integrate IrisAgent with Freshworks</a></li>
                    <li><a href="https://irisagent.com/intercom-docs/">Integrate IrisAgent with Intercom</a></li>
                    <li><a href="https://irisagent.com/service-desk-docs/">Integrate IrisAgent with Jira Service Desk</a></li>
                    <li><a href="https://irisagent.com/atlassian-docs/">Integrate IrisAgent with Atlassian products: Jira Software and Confluence</a></li>
                    <li><a href="https://irisagent.com/slack-bot-ai-docs/">Integrate IrisGPT bot with Slack</a></li>
                    </ul>
                    <br/>
                    <h2>Deploy IrisGPT chatbot and intelligent search for self service</h2>
                    <ul>
                    <li><a href="https://irisagent.com/chatbot-docs/">Deploy IrisGPT chatbot</a></li>
                    <li><a href="https://irisagent.com/irisgpt-chatbot-zendesk-help-center/">Deploy IrisGPT chatbot on Zendesk Help Center</a></li>
                    <li><a href="https://irisagent.com/ic-chat-docs/">Integrate IrisGPT AI with your Intercom chatbot</a></li>
                    <li><a href="https://irisagent.com/search-form-docs/">Integrate IrisAgent's intelligent search on Zendesk Ticket Form page</a></li>
                    </ul>

                    <br/>
                    <h2>Set up automated ticket tagging, routing, and other workflows</h2>
                    <ul>
                    <li><a href="https://irisagent.com/automation-docs/">Set up Automated tagging and other workflows on IrisAgent</a></li>
                    <li><a href="https://irisagent.com/ticket-deflection-docs/">Set up Ticket Deflection</a></li>
                    <li><a href="https://irisagent.com/routing/">Set up Automated routing</a></li>
                    </ul>
                    <br/>
                    <h2>Other documentation</h2>
                    <ul>
                    <li><a href="https://irisagent.com/sso-docs/">Learn more about available SSO options</a></li>
                    <li><a href="https://irisagent.com/okta-docs/">Sign in to IrisAgent with Okta SSO</a></li>
                    <li><a href="https://irisagent.com/macro-recommendation-docs/">Use AI to recommend and automate Zendesk Macros</a></li>
                    <li><a href="https://irisagent.com/search-api-docs/">Use API to access IrisAgent Intelligent Search</a></li>
                    <li><a href="https://irisagent.com/irisgpt-api-docs/">Use API to access IrisGPT AI Answers</a></li>
                    <li><a href="https://irisagent.com/switch-user-docs/">Change integration user</a></li>
                    </ul>
                    </div>
                    </div>
                    </section>

</Layout>
  )
}
