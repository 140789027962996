import React from "react"
import { Layout } from "../components/Layout"
import {
  SectionDivider,
  SectionDividerMobile,
  ServiceSection,
  DemoButton,
  LearnMoreButton,
  GetStartedButton,
  CountsSection,
  Testimonials,
  Faq,
  AutoScrollBanner
} from "../commonComponents"
import {
  IntegrationDarkSection
} from "../integrationsUtils"
import { Helmet } from "react-helmet"

export default function HealthcareSupportAI() {
  return (
    <Layout
      keywords="healthcare ai agent assist, healthcare chatbot, health tech chatbot, healthcare conversational ai"
      title="Best AI Agent Assist and Chatbot for Healthcare Sector | IrisAgent"
      description="Transform healthcare and health tech customer experiences with AI-powered agile, effective and human-like automated responses."
    >
      <Helmet htmlAttributes={{ lang: 'en' }}>
        {" "}
        <link
          rel="canonical"
          href="https://irisagent.com/healthcare-support-ai/"
        />
      </Helmet>
      {/* <!-- ======= Hero Section ======= --> */}
      <section
        id="hero-new-design"
        className="agent-assistant-section-padding-top"
      >
        <div className="row" style={{ width: "100%", margin: 0 }}>
          <div
            className="col-md-6 section-padding-left"
            style={{ paddingLeft: "0" }}
          >
            <h1 className="title-support-new-design">
              Transform healthcare customer experiences with <span style={{ color: "#EF5DA8" }}>smart AI responses</span>
            </h1>
            <div className="description-support-new-design">
            <ul className="checkbox-list">
              <li style={{ paddingLeft: "10px" }}> Automate up to 60% of interactions by <br/>delivering 24X7 support to your customers</li>
              <li style={{ paddingLeft: "10px" }}> Enable virtual patient assistance  from treatment<br/> to insurance with GenAI bots</li>
              <li style={{ paddingLeft: "10px" }}> Assist frontline teams with relevant information</li>
            </ul>
            </div>
            <br />
            <div className="description-button-support-new-design">
              <LearnMoreButton

            label="TRY NOW FOR FREE"
            to="/try-for-free"
          />
            </div>
          </div>
          <div className="col-md-6" style={{ padding: 0 }}>
            <div className="healthcare-background"></div>
          </div>
        </div>
      </section>
      {/* <!-- End Hero --> */}

      <AutoScrollBanner />

{/*  ======= Cta Section =======  */}
      <IntegrationDarkSection
        label={
          <span className="integration-dark-section-title">
            Try{" "}
            <span className="title-fucsia-color">IrisGPT</span> on your own data for free
          </span>
        }
        button={
          <LearnMoreButton

            label="TRY NOW FOR FREE"
            to="/try-for-free"
          />
        }
        firstColClassName="col-md-8"
        secondColClassName="col-md-4"
        buttonWebFlexAlignment="end"
      />
      {/*  End Cta Section  */}
      <section
        id="about"
        className="about auto-detect-section auto-detect-section-less-top section-padding-right"
        style={{ marginBottom: "5%", marginLeft: "4%", marginTop: "3%" }}
      >
        <div className="row">
          <div className="col-md-12" style={{ display: "flex", width: "100%" }}>
            <SectionDividerMobile color="#7879F1"></SectionDividerMobile>
            <div
              className="auto-detect-img-not-mobile"
              style={{
                width: "40%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src="/img/healthcare_workflows.png"
                style={{ width: "88%", margin: "10%" }}
                alt="healthcare workflows"
              />
            </div>
            <SectionDivider color="#7879F1"></SectionDivider>
            <div
              className="auto-detect-info-container-50"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <div className="auto-detect-subcontainer-mobile">
                <div className="mobile-view-new-design-display">
                  <img
                    src="/img/healthcare_workflows.png"
                    alt="healthcare workflows"
                    style={{ width: "88%", margin: "10%" }}
                  />
                </div>
                <h2
                  className="section-title-new-design auto-detect-title agent-assistant-auto-detect-title"
                  style={{ marginBottom: "5%" }}
                >
                <span>
                  AI-powered automated workflows for your healthcare interactions
                 </span>
                 </h2>
                  <div className="generic-text"
                  style={{ marginBottom: "5%" }}
                  >
                  <span>
                  <ul className="checkbox-list">
                    <li style={{ paddingLeft: "20px" }}><a href="https://irisagent.com/support-agent-assist/">IrisGPT GenAI chatbot</a> using LLMs efficiently responds to 60%+ healthcare queries with human-like responses. Automate tasks like health insurance invoicing, scheduling appointments, hospital policies, patient refunds, etc. </li>
                    <li style={{ paddingLeft: "20px" }}> Automated workflows with AI driven intent detection of all interactions and efficiently route and resolve issues faster. No more inaccurate manual monitoring and routing for inbound enquiries! </li>

                   </ul>
                  </span>
                  </div>
                <div style={{ display: "flex" }}>
                  <DemoButton />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End About Auto-detected incidents Section --> */}
      {/* <!-- ======= About Similar Cases Section ======= --> */}
      <section
        id="about"
        className="about auto-detect-section auto-detect-section-less-top section-padding-left-mobile section-padding-right"
        style={{ marginBottom: "5%" }}
      >
        <div className="row">
          <div className="col-md-12" style={{ display: "flex", width: "100%" }}>
            <SectionDividerMobile color="#EF5DA8"></SectionDividerMobile>
            <div
              className="auto-detect-info-container-40"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div>
                <div className="mobile-view-new-design-display">
                  <img
                    src="/img/healthcare_sentiment.png"
                    style={{
                      width: "94%",
                      margin: "10%",
                    }}
                    alt="about section"
                  />
                </div>
                <div
                  className="section-title-new-design auto-detect-title"
                  style={{ marginBottom: "5%" }}
                >
                  <h2
                    className="section-title-new-design auto-detect-title"
                    style={{ marginBottom: "5%" }}
                  >
                    <span>
                      Empower support teams and prevent escalations with real-time sentiment analysis
                      <br />
                    </span>
                    </h2>
                    <div className="generic-text"
                    style={{ marginBottom: "5%" }}
                    >
                    <span>
                    <ul className="checkbox-list">
                      <li style={{ paddingLeft: "20px" }}>Give agents real-time insights from knowledge bases, and historical medical and ticketing data</li>
                      <li style={{ paddingLeft: "20px" }}> Discover at-risk tickets with automated customer sentiment impact analysis </li>
                      <li style={{ paddingLeft: "20px" }}> Get real-time alerts about trending product and customer experience issues. Custom-made and fine-tuned for Healthcare companies. </li>
                    </ul>
                    </span>
                    </div>
                </div>
                <div style={{ display: "flex" }}>
                  <GetStartedButton />
                </div>
              </div>
            </div>
            <SectionDivider color="#EF5DA8" flipped={true}></SectionDivider>
            <div
              className="auto-detect-img-not-mobile"
              style={{
                width: "50%",
                display: "flex",
                alignItems: "baseline",
                justifyContent: "flex-start",
              }}
            >
              <img
                src="/img/healthcare_sentiment.png"
                style={{
                  width: "90%",
                  margin: "10%"
                }}
                alt="about section"
              />
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End About Similar Cases Section --> */}

      <section className="d-flex align-items-center  sub-section-2-container">
        <div style={{ width: "100%" }}>
          <div className="row">
            <SecurityCard
              title="Secure Hosting"
              body="IrisAgent SaaS platform is hosted in a secure cloud infrastructure"
              image="/img/security-2.svg"
            />
            <SecurityCard
              title="Encrypted Data"
              body=" All the data collection happens securely over SSL using REST APIs"
              image="/img/security-3.svg"
            />
            <SecurityCard
              title="Single Sign-On"
              body="We do not require additional login credentials. We authenticate through Google, Microsoft and Salesforce."
              image="/img/security-4.svg"
            />
          </div>
        </div>
      </section>


      {/* <!-- ======= Counts Section ======= --> */}
      <CountsSection theme="light" />
      {/* <!-- End Counts Section --> */}

                          <section
        className="d-flex align-items-center sub-section-3-container"
        // style={{ margin: "100px" }}
      >
        <div className="">
          <div className="row">
            <div className="col-sm order-1 order-lg-1">
              <div style={{ marginLeft: "40px" }}>
                <p className="sub-section-3-h1">Any questions?</p>
                <p className="sub-section-3-h2"> We got you.</p>
                <img
                  src="/img/security-5.svg"
                  className="img-fluid"
                  alt="secure"
                />
              </div>
            </div>
            <div className="col-sm pt-5 pt-lg-0 order-2 order-lg-2 d-flex flex-column justify-content-center">
              <div id="accordion">
                <Faq
                  id="faq1"
                  question="What is conversational AI in healthcare?"
                  answer="Conversational AI in healthcare uses artificial intelligence technologies, such as chatbots and virtual assistants, to facilitate interactive, human-like conversations between healthcare companies and their customers. These AI-powered systems are designed to understand and process natural language, allowing customers to perform tasks related to their healthcare service, get personalized medical/health advice, resolve queries, and receive instant support through text or voice commands. By automating customer service and enhancing user experience, conversational AI transforms how healthcare companies operate and interact with their customers, making healthcare more accessible, efficient, and personalized."
                />
                <Faq
                  id="faq2"
                  question="What is conversational healthcare support?"
                  answer="Conversational healthcare support is a customer service approach in healthcare that utilizes chatbots, voice assistants, and messaging platforms to provide a more interactive and personalized healthcare customer service experience. It allows customers to request claims, access insurance or medical account information, receive health advice, and get support through natural language conversations via text or voice. This method aims to make healthcare more accessible and convenient, enabling customers to receive the best healthcare advice seamlessly in real-time without needing to visit physical branches or navigate complex healthcare company websites."
                />
                <Faq
                  id="faq3"
                  question="How can AI be used in healthcare and medical services?"
                  answer="AI can be used in healthcare and the medical services industry to enhance customer service, improve security, and streamline operations. It enables personalized patient support through chatbots, insurance fraud detection with machine learning algorithms, and automated underwriting for quicker medical loan approvals. AI also optimizes back-office processes, like document verification and risk management, and offers insights for better decision-making through data analysis. This integration of AI in healthcare leads to more efficient operations, reduced costs, and an improved customer experience, making healthcare more accessible, secure, and tailored to individual needs."
                />
                <Faq
                  id="faq4"
                  question="How is ChatGPT used in the healthcare industry?"
                  answer="ChatGPT is used in the healthcare industry to provide automated customer support, handle inquiries, and offer personalized patient advice through natural language processing. It can answer questions, assist customers with invoice payments and claims, and guide users through various healthcare services, enhancing customer experience with its ability to understand and respond conversationally. Healthtech also utilizes ChatGPT for internal purposes, such as training staff or automating routine tasks, improving operational efficiency, and reducing the workload on human employees."
                />
                <Faq
                  id="faq5"
                  question="Can conversational AI handle complex patient requests?"
                  answer="Yes, advanced conversational AI systems are capable of handling complex patient requests, such as appointment scheduling, patient care management, paying insurance bills, or applying for medical loans, by securely processing user requests and integrating with the organization’s knowledge systems."
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- ======= Services Section ======= --> */}
      <ServiceSection />
      {/* <!-- End Services Section --> */}



      {/* <!-- ======= Contact Services Section ======= --> */}
      {/* <ContactSection /> */}
      {/* <!-- End Contact Section --> */}
    </Layout>
  )
}
// todo: move to commonComponents as Security page also uses it
const SecurityCard = ({ title, body, image }: any) => {
  return (
    <div
      className="col-sm order-1 order-lg-1 "
      style={{ textAlign: "center", marginLeft: "10px", marginRight: "10px" }}
    >
      <img src={image} className=" sub-section-2-img" alt="secure" />
      <h4 className="sub-section-2-h">{title}</h4>
      <p className="sub-section-2-p">{body}</p>
    </div>
  )
}
