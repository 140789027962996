import React from "react"
import { Helmet } from "react-helmet"
import { Layout } from "../components/Layout"
import { Link } from "gatsby"
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { prism } from 'react-syntax-highlighter/dist/esm/styles/prism';


export default function IrisGPTAPIDocs() {
  const json = {
  "result":
    {
   "htmltext":"Agents can leverage knowledge to help customers by using the Knowledge Capture app. This app allows agents to search the Help Center without leaving the ticket, insert links to relevant Help Center articles in ticket comments, add inline feedback to existing articles, and create new articles while answering tickets using a pre-defined template. This helps agents assist customers effectively while also enhancing self-service options for other customers.\n\u003cbr\u003e\nCheck out this article for more information:\n\u003cul\u003e \u003cli style=\"list-style-position: inside;\"\u003e\u003ca href='https://d3v-irisagent.zendesk.com/hc/en-us/articles/360046592594-How-can-agents-leverage-knowledge-to-help-customers' target='_blank'\u003eHow can agents leverage knowledge to help customers?\u003c/a\u003e\u003c/li\u003e \u003c/ul\u003e",
   "links":[
      {
         "text":"How can agents leverage knowledge to help customers?",
         "url":"https://d3v-irisagent.zendesk.com/hc/en-us/articles/360046592594-How-can-agents-leverage-knowledge-to-help-customers"
      }
   ],
   "linksTitle":"Check out this article for more information:",
   "plaintext":"Agents can leverage knowledge to help customers by using the Knowledge Capture app. This app allows agents to search the Help Center without leaving the ticket, insert links to relevant Help Center articles in ticket comments, add inline feedback to existing articles, and create new articles while answering tickets using a pre-defined template. This helps agents assist customers effectively while also enhancing self-service options for other customers.",
   "mdText":"Agents can leverage knowledge to help customers by using the Knowledge Capture app. This app allows agents to search the Help Center without leaving the ticket, insert links to relevant Help Center articles in ticket comments, add inline feedback to existing articles, and create new articles while answering tickets using a pre-defined template. This helps agents assist customers effectively while also enhancing self-service options for other customers. - Check if the control number is mandatory for the account you are trying to use. If it is, make sure the control number is correctly entered.\n- Verify that you have the necessary permissions to edit or create journal entries, as permissions can affect your ability to make changes.\n- If the journal entry is already posted, you may need to reverse it and create a new entry with the correct control number.\n## Check out the following resource(s) for more information:\n- [Need to change control while Journal Entry Posting.](https://example.com/670da173611ddba46ef12ed1)\n- [ACCOUNTING SETTINGS: Journals](https://example.com/670da22d611ddba46ef13675)",
   "source":"python-ml",
   "urls":[
      "https://d3v-irisagent.zendesk.com/hc/en-us/articles/360046592594-How-can-agents-leverage-knowledge-to-help-customers"
   ]
}
}

  return (
    <Layout
      keywords="IrisGPT API guide"
      title="Use IrisGPT's API to power intelligent AI answers in your products"
      description="Documentation on how to use IrisGPT's API to power intelligent, Gen-AI answers in your products"
    >
    <Helmet htmlAttributes={{ lang: 'en' }}>
        <link
          rel="canonical"
          href="https://irisagent.com/irisgpt-api-docs/"
        />
      </Helmet>
      <section class="inner-page">
          <div class="container">
            <div data-custom-class="body"
            style={{ marginBottom: "5%", marginTop: "5%", margin: "10%" }}>
            <br/><br/><br/>
                    <h1>IrisGPT API Documentation</h1>
                    <br/>
                    <h2>
                    Introduction
                    </h2>
                    <br/>

                    IrisAgent's <a href="https://irisagent.com/support-agent-assist/">IrisGPT AI answers</a> can be used an API to power intelligent conversations on any of your frontend webpages. It can search through your internal and external knowledge articles, community forums, product documentation, and other informational content.
                    <br/>
                    <br/>
                    <h2>
                    API Guide
                    </h2>
                    <br/>
                    Below is a <span style={{"font-family": "Consolas"}}>curl</span> command to call our IrisGPT API:
                    <SyntaxHighlighter language="javascript" style={prism}>
  {`
curl -X POST --location "https://frontend-api-server-v2.api.irisagent.com/v1/irisgpt/ask" \\
    -H "Content-Type: application/json" \\
    -H "Authorization: Bearer REPLACE_ME_BUSINESS_ID" \\
    -H "X-Language-Iso-Code: en" \\
    -d '{
          "query": "how do agents use knowledge"
        }'
          `}
</SyntaxHighlighter>
<br/>
Here, the inputs are:
<br/><br/>
<ul>
    <li>
    <span style={{"font-family": "Consolas"}}>query</span>: [Required] URL-encoded query from the user
    </li>
    <li>
    <span style={{"font-family": "Consolas"}}>REPLACE_ME_BUSINESS_ID</span>: This is the ID provided by the IrisAgent team
    </li>
</ul>
<br/>
Below is a sample output of the API:
<br/>
<SyntaxHighlighter language="javascript" style={prism}>
{JSON.stringify(json, null, 2)}
</SyntaxHighlighter>

                    </div>
                    </div>
                    </section>

</Layout>
  )
}